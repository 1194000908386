import { initDepartment, loadDepartment, searchDepartment } from "@/api/index";
export default {
  name: "departmentTreeChoose",
  props: {
    text: {
      type: String,
      default: "选择部门"
    },
    icon: {
      type: String,
      default: "md-list"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: String,
    type: String,
    shape: String,
    ghost: {
      type: Boolean,
      default: false
    },
    prefix: String,
    suffix: String,
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: "点击选择部门"
    }
  },
  data() {
    return {
      depLoading: false,
      departmentTitle: "",
      searchKey: "",
      dataDep: [],
      selectDep: [],
      departmentId: []
    };
  },
  methods: {
    initDepartmentData() {
      initDepartment().then(res => {
        if (res.success) {
          res.result.forEach(function (e) {
            if (e.isParent) {
              e.loading = false;
              e.children = [];
            }
            if (e.status == -1) {
              e.title = "[已禁用] " + e.title;
              e.disabled = true;
            }
          });
          this.dataDep = res.result;
        }
      });
    },
    loadData(item, callback) {
      loadDepartment(item.id).then(res => {
        if (res.success) {
          res.result.forEach(function (e) {
            if (e.isParent) {
              e.loading = false;
              e.children = [];
            }
            if (e.status == -1) {
              e.title = "[已禁用] " + e.title;
              e.disabled = true;
            }
          });
          callback(res.result);
        }
      });
    },
    searchDep() {
      // 搜索部门
      if (this.searchKey) {
        this.depLoading = true;
        searchDepartment({
          title: this.searchKey
        }).then(res => {
          this.depLoading = false;
          if (res.success) {
            res.result.forEach(function (e) {
              if (e.status == -1) {
                e.title = "[已禁用] " + e.title;
                e.disabled = true;
              }
            });
            this.dataDep = res.result;
          }
        });
      } else {
        this.initDepartmentData();
      }
    },
    selectTree(v) {
      let ids = [],
        title = "";
      v.forEach(e => {
        ids.push(e.id);
        if (title == "") {
          title = e.title;
        } else {
          title = title + "、" + e.title;
        }
      });
      this.departmentId = ids;
      this.departmentTitle = title;
      if (this.multiple) {
        this.$emit("on-change", this.departmentId);
      } else {
        this.$emit("on-change", this.departmentId[0]);
      }
    },
    clearSelect() {
      this.departmentId = [];
      this.departmentTitle = "";
      this.initDepartmentData();
      if (this.multiple) {
        this.$emit("on-change", []);
      } else {
        this.$emit("on-change", "");
      }
      this.$emit("on-clear");
    },
    setData(ids, title) {
      this.departmentTitle = title;
      if (this.multiple) {
        this.departmentId = ids;
      } else {
        this.departmentId = [];
        this.departmentId.push(ids);
      }
      this.$emit("on-change", this.departmentId);
    }
  },
  mounted() {
    this.initDepartmentData();
  }
};