import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
import { uploadFile } from "@/api/index";
import materialCenter from "@/views/my-components/xboot/material-center";
export default {
  name: "uploadPicInput",
  components: {
    materialCenter
  },
  props: {
    value: String,
    size: String,
    placeholder: {
      type: String,
      default: "可输入图片链接"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    material: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maxlength: Number,
    previewIcon: {
      type: String,
      default: "md-eye"
    },
    icon: {
      type: String,
      default: "ios-cloud-upload-outline"
    },
    text: {
      type: String,
      default: "上传图片"
    },
    type: String,
    shape: String,
    ghost: {
      type: Boolean,
      default: false
    },
    maxSize: {
      type: Number,
      default: 5
    },
    accept: {
      type: String,
      default: ".jpg, .jpeg, .png, .gif"
    },
    showUpload: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    format() {
      if (this.accept) {
        let format = [];
        this.accept.split(",").forEach(e => {
          format.push(e.replace(".", "").replace(" ", ""));
        });
        return format;
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      accessToken: {},
      currentValue: this.value,
      loading: false,
      uploadFileUrl: uploadFile,
      showMaterialCenter: false
    };
  },
  methods: {
    init() {
      this.accessToken = {
        accessToken: this.getStore("accessToken")
      };
    },
    viewImage() {
      let image = new Image();
      image.src = this.currentValue;
      let viewer = new Viewer(image, {
        hidden: function () {
          viewer.destroy();
        }
      });
      viewer.show();
    },
    handleFormatError(file) {
      this.loading = false;
      this.$Notice.warning({
        title: "不支持的文件格式",
        desc: "所选文件‘ " + file.name + " ’格式不正确, 请选择 " + this.accept + " 格式文件"
      });
    },
    handleMaxSize(file) {
      this.loading = false;
      this.$Notice.warning({
        title: "文件大小过大",
        desc: "所选文件‘ " + file.name + " ’大小过大, 不得超过 " + this.maxSize + "M."
      });
    },
    beforeUpload() {
      this.loading = true;
      return true;
    },
    handleSuccess(res, file) {
      this.loading = false;
      if (res.success) {
        this.currentValue = res.result;
        this.$emit("input", this.currentValue);
        this.$emit("on-change", this.currentValue);
      } else {
        this.$Message.error(res.message);
      }
    },
    handleError(error, file, fileList) {
      this.loading = false;
      this.$Message.error(error.toString());
    },
    handleChange(v) {
      this.$emit("input", this.currentValue);
      this.$emit("on-change", this.currentValue);
    },
    setCurrentValue(value) {
      if (value === this.currentValue) {
        return;
      }
      this.currentValue = value;
      this.$emit("on-change", this.currentValue);
    },
    selectFile(v) {
      this.setCurrentValue(v);
    }
  },
  watch: {
    value(val) {
      this.setCurrentValue(val);
    }
  },
  mounted() {
    this.init();
  }
};