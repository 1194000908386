var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "user-edit"
  }, [_c('Drawer', {
    attrs: {
      "title": _vm.title,
      "width": "720",
      "draggable": "",
      "mask-closable": _vm.type == '0'
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('div', {
    staticClass: "drawer-content",
    style: {
      maxHeight: _vm.maxHeight
    }
  }, [_c('div', {
    staticClass: "drawer-header"
  }, [_c('div', {
    staticStyle: {
      "margin-right": "16px"
    }
  }, [_vm._v("基本信息")]), _c('Avatar', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type != '2',
      expression: "type != '2'"
    }],
    attrs: {
      "src": _vm.form.avatar,
      "size": "large"
    }
  })], 1), _c('Form', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type != '2',
      expression: "type != '2'"
    }],
    attrs: {
      "label-colon": ""
    }
  }, [_c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "用户ID"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.id) + " "), _c('Tooltip', {
    attrs: {
      "trigger": "hover",
      "placement": "right",
      "content": "账户已禁用"
    }
  }, [_c('Icon', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.status == -1,
      expression: "form.status == -1"
    }],
    staticStyle: {
      "margin-left": "10px",
      "cursor": "pointer"
    },
    attrs: {
      "type": "md-lock",
      "size": "18"
    }
  })], 1)], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "登录账号"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.username) + " "), _c('Tooltip', {
    attrs: {
      "trigger": "hover",
      "placement": "right",
      "content": `密码强度：${_vm.form.passStrength}`
    }
  }, [_c('Icon', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.passStrength,
      expression: "form.passStrength"
    }],
    staticStyle: {
      "margin-left": "10px",
      "cursor": "pointer"
    },
    attrs: {
      "type": "md-key",
      "color": _vm.passColor,
      "size": "18"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('Form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.formValidate,
      "label-position": "top"
    }
  }, [_vm.type == '2' ? _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "登录账号",
      "prop": "username"
    }
  }, [_c('Input', {
    attrs: {
      "autocomplete": "off",
      "maxlength": 16
    },
    model: {
      value: _vm.form.username,
      callback: function ($$v) {
        _vm.$set(_vm.form, "username", $$v);
      },
      expression: "form.username"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "密码",
      "prop": "password"
    }
  }, [_c('SetPassword', {
    on: {
      "on-change": _vm.changePass
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "用户名",
      "prop": "nickname"
    }
  }, [_c('Input', {
    model: {
      value: _vm.form.nickname,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nickname", $$v);
      },
      expression: "form.nickname"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "邮箱",
      "prop": "email"
    }
  }, [_c('Input', {
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "手机号",
      "prop": "mobile"
    }
  }, [_c('Input', {
    model: {
      value: _vm.form.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.form, "mobile", $$v);
      },
      expression: "form.mobile"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "性别"
    }
  }, [_c('dict', {
    attrs: {
      "dict": "sex",
      "transfer": ""
    },
    model: {
      value: _vm.form.sex,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sex", $$v);
      },
      expression: "form.sex"
    }
  })], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    staticClass: "form-noheight",
    attrs: {
      "label": "所属部门"
    }
  }, [_c('department-tree-choose', {
    ref: "depTree",
    on: {
      "on-change": _vm.handleSelectDepTree
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "用户类型"
    }
  }, [_c('Select', {
    attrs: {
      "transfer": "",
      "placeholder": "请选择"
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_c('Option', {
    attrs: {
      "value": 0
    }
  }, [_vm._v("普通用户")]), _c('Option', {
    attrs: {
      "value": 1
    }
  }, [_vm._v("管理员")])], 1)], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    staticClass: "form-noheight",
    attrs: {
      "label": "头像"
    }
  }, [_c('upload-pic-input', {
    model: {
      value: _vm.form.avatar,
      callback: function ($$v) {
        _vm.$set(_vm.form, "avatar", $$v);
      },
      expression: "form.avatar"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "角色分配",
      "prop": "roleIds"
    }
  }, [_c('Select', {
    attrs: {
      "multiple": "",
      "transfer": ""
    },
    model: {
      value: _vm.form.roleIds,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roleIds", $$v);
      },
      expression: "form.roleIds"
    }
  }, _vm._l(_vm.roleList, function (item) {
    return _c('Option', {
      key: item.id,
      attrs: {
        "value": item.id,
        "label": item.name
      }
    }, [_c('span', {
      staticStyle: {
        "margin-right": "10px"
      }
    }, [_vm._v(_vm._s(item.name))]), _c('span', {
      staticStyle: {
        "color": "#ccc"
      }
    }, [_vm._v(_vm._s(item.description))])]);
  }), 1)], 1)], 1)], 1), _c('Divider'), _c('p', {
    staticClass: "drawer-title"
  }, [_vm._v("个人资料")]), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "所在地区",
      "prop": "address"
    }
  }, [_c('al-cascader', {
    attrs: {
      "data-type": "code",
      "level": "2",
      "transfer": ""
    },
    model: {
      value: _vm.form.address,
      callback: function ($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "街道地址"
    }
  }, [_c('Input', {
    model: {
      value: _vm.form.street,
      callback: function ($$v) {
        _vm.$set(_vm.form, "street", $$v);
      },
      expression: "form.street"
    }
  })], 1)], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "生日",
      "prop": "birth"
    }
  }, [_c('DatePicker', {
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "type": "date",
      "transfer": ""
    },
    model: {
      value: _vm.form.birth,
      callback: function ($$v) {
        _vm.$set(_vm.form, "birth", $$v);
      },
      expression: "form.birth"
    }
  })], 1)], 1), _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "简介"
    }
  }, [_c('Input', {
    attrs: {
      "type": "textarea",
      "rows": 4
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.type != '0',
      expression: "type != '0'"
    }],
    staticClass: "drawer-footer br"
  }, [_c('Button', {
    attrs: {
      "type": "primary",
      "loading": _vm.submitLoading
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")]), _c('Button', {
    on: {
      "click": function ($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("取消")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };